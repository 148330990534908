import styled from 'styled-components';

import theme from 'utils/theme';

interface Heading2Props {
  inline?: boolean;
}

const Heading2 = styled.h2<Heading2Props>`
  margin-top: 0;
  margin-bottom: ${({ inline }) => (inline ? 0 : '20px')};
  font-size: var(--step-7);
  font-weight: ${theme.weights.headingBold};
  line-height: 1.1;
  letter-spacing: -0.2px;
  color: inherit;
  font-family: ${theme.fonts.heading};

  p + & {
    margin-top: 1em;
  }
`;

export default Heading2;
