import styled from 'styled-components';

import { mobile } from 'utils/media';
import theme from 'utils/theme';

export const Wrapper = styled.div`
  background: ${theme.colors.lightGreen};
  text-align: center;
  font-size: var(--step--1);
  z-index: ${theme.zIndexes.modals};
  position: relative;
  overflow: hidden;

  ${mobile} {
    font-size: var(--step--2);
  }
`;

export const BannerTopFadeOut = styled.span`
  display: grid;
  align-items: center;
  width: 100%;
`;

interface HeaderAlertLinkProps {
  active: boolean;
}

export const HeaderAlertLink = styled.a<HeaderAlertLinkProps>`
  background: ${theme.colors.lightGreen};
  grid-column: -1 / 1;
  grid-row: -1 / 1;
  width: 100%;
  padding: var(--space-2xs);
  color: ${theme.colors.black};
  display: block;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity ${theme.transitionSpeeds.normal}s ease-in-out;
  transition-delay: ${({ active }) =>
    active ? `${theme.transitionSpeeds.normal}s` : 0};
  z-index: ${({ active }) => (active ? 1 : 0)};
`;
