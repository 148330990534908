import styled from 'styled-components';
import Modal from 'styled-react-modal';

import { tablet } from 'utils/media';
import theme from 'utils/theme';

interface IModalProps {
  opacity: number;
}

export const StyledModal = styled(Modal)<IModalProps>`
  opacity: ${(props) => props.opacity};
  transition: all ${theme.transitionSpeeds.slow}s ease-in-out;
`;

export const Wrapper = styled.div`
  background-color: ${theme.colors.offWhite};
  border-radius: 30px;
  max-width: 100vw;
  padding: 18px 24px;
  width: calc(100% - 48px);

  ${tablet} {
    max-width: 375px;
    width: 100%;
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: top;
  justify-content: space-between;
  width: 100%;
  font-size: var(--step-2);
`;

export const CloseButton = styled.button`
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  height: 30px;
  width: 30px;
`;

export const HeadingText = styled.span`
  color: ${theme.colors.purple};
  font-family: ${theme.fonts.heading};
`;
