import styled from 'styled-components';

import { Heading2 } from 'components/common/text';
import TitleLink from 'components/common/text/heading-link';
import { tablet } from 'utils/media';
import theme from 'utils/theme';

const HeadingWrapper = styled.div`
  display: flex;
  align-items: baseline;
  padding: 0 20px;
  color: ${theme.colors.purple};
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding: 0 20px;

  ${Heading2} {
    font-size: var(--step-6);
  }

  ${tablet} {
    padding: 0 40px;

    ${TitleLink} {
      margin-left: 30px;
    }
  }
`;

export default HeadingWrapper;
