import styled from 'styled-components';

import { tablet } from 'utils/media';

const HeadingLink = styled.a`
  font-weight: bold;
  font-size: var(--step-0);
  line-height: 24px;
  letter-spacing: -0.02em;
  color: inherit;
  text-decoration: underline;
  flex: 100%;

  ${tablet} {
    position: relative;
    text-decoration: none;
    flex: auto 0;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      height: var(--space-4xs);
      left: 0;
      width: 100%;
      transition: transform 0.2s ease-out;
      background-color: currentColor;
      transform: scaleX(0);
      transform-origin: left;
    }

    &:focus:after,
    &:hover:after {
      transform: scaleX(100%);
    }
  }
`;

export default HeadingLink;
