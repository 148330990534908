import Head from 'next/head';
import React, { ReactElement, ReactNode } from 'react';
import { ModalProvider } from 'styled-react-modal';

import CookieBanner from 'components/common/cookie-banner';
import Footer from 'components/common/footer';
import Header from 'components/common/header';
import HeaderAlert from 'components/common/header-alert';
import PressBanner from 'components/common/press-banner';
import EmailPopup from 'components/email/email-popup';
import { ISiteGlobals } from 'utils/types';

import { LayoutProps } from '../types';

import {
  StickyGlobalHeader,
  SpecialModalBackground,
  HeaderAlertContainer,
} from './styles';

export default function DefaultLayout({
  children,
  siteGlobals,
  enableEmailPopup = true,
}: LayoutProps) {
  return (
    <>
      <Head>
        <title>Sproutl</title>
        <meta
          name="description"
          content="Growing for everyone. Beautiful plants for any garden, delivered to your door."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      {siteGlobals.header?.alerts ? (
        <HeaderAlertContainer>
          <HeaderAlert alerts={siteGlobals.header?.alerts || []} />
        </HeaderAlertContainer>
      ) : null}

      <StickyGlobalHeader>
        <Header nav={siteGlobals.header?.menu || []} />
      </StickyGlobalHeader>

      <ModalProvider backgroundComponent={SpecialModalBackground}>
        {children}
        <PressBanner images={siteGlobals.pressBanner?.images} />
        <Footer
          linkColumns={siteGlobals.footer?.linkColumns}
          newsletterColumn={siteGlobals.footer?.newsletterColumn}
        />
        {enableEmailPopup && <EmailPopup />}
      </ModalProvider>

      <CookieBanner />
    </>
  );
}

export function getDefaultLayout(
  page: ReactElement,
  siteGlobals: ISiteGlobals,
): ReactNode {
  return <DefaultLayout siteGlobals={siteGlobals}>{page}</DefaultLayout>;
}
