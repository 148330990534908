import { last } from 'lodash';

import { LineItemDataFragment, MoneyFragment } from 'generated/api/graphql';
import { AddedLineItem, IGroupedOrder } from 'utils/commercetools/types';
import { convertCentAmountToDollars } from 'utils/helpers';

import { PopupTimeProps } from './types';
import {
  dataLayerEcomPush,
  dataLayerPush,
  mapLineItemForGA4,
  mapLineItemForUA,
  mapProductForGA4,
  mapProductForUA,
} from './utils';

/**
 * Track view category / search / partner page
 * @param list pageName - Search Page | Partner Page | Category Page
 * @param products
 */
export function trackViewProductsList(
  list: string,
  products: Sproutl.ProductListing[],
) {
  dataLayerEcomPush({
    event: 'view_item_list',
    ecommerce: {
      // UA
      currencyCode: 'GBP',
      impressions: products.map(mapProductForUA(list)),
      // GA4
      item_list_name: list,
      items: products.map(mapProductForGA4),
    },
  });
}

/**
 * Track view product details page
 * @param product Product being viewed
 * @param bestOffer Price and availability from CT
 */
export function trackViewProduct(
  { name, sku, categories }: Sproutl.ProductVariant,
  bestOffer: Sproutl.ProductOffer | null,
) {
  dataLayerEcomPush({
    event: 'view_item',
    ecommerce: {
      // UA
      detail: {
        products: [
          {
            name,
            id: sku,
            price:
              bestOffer &&
              bestOffer.price &&
              convertCentAmountToDollars(bestOffer.price.centAmount),
            category: last(categories)?.slug,
          },
        ],
      },
      // GA4
      items: [
        {
          item_name: name,
          item_id: sku,
          price:
            bestOffer &&
            bestOffer.price &&
            convertCentAmountToDollars(bestOffer.price.centAmount),
          item_category: last(categories)?.slug,
        },
      ],
    },
  });
}

/**
 * Track add to basket
 * @param lineItems Products being added (can be multiple with pot / plant)
 */
export function trackAddToBasket(lineItems: AddedLineItem[]) {
  dataLayerEcomPush({
    event: 'add_to_cart',
    ecommerce: {
      // UA
      currencyCode: 'GBP',
      add: {
        products: lineItems.map(({ name, sku, quantity, price }) => ({
          name,
          id: sku,
          price: price && convertCentAmountToDollars(price.centAmount),
          quantity,
        })),
      },
      // GA4
      currency: 'GBP',
      value: convertCentAmountToDollars(
        lineItems.reduce((sum, item) => sum + item?.price?.centAmount, 0),
      ),
      items: lineItems.map(({ name, sku, quantity, price }) => ({
        item_name: name,
        item_id: sku,
        price,
        quantity,
      })),
    },
  });
}

/**
 * Track remove item from basket
 * @param lineItem CT LineItem being removed
 */
export function trackRemoveFromBasket(lineItem: LineItemDataFragment) {
  dataLayerEcomPush({
    event: 'remove_from_cart',
    ecommerce: {
      // UA
      remove: {
        products: [mapLineItemForUA(lineItem)],
      },
      // GA4
      items: [mapLineItemForGA4(lineItem)],
    },
  });
}

/**
 * Track view basket page
 * UA doesn't have an event for this
 * @param cart
 */
export function trackViewBasket(
  totalPrice: MoneyFragment,
  lineItems: LineItemDataFragment[],
) {
  // GA4
  dataLayerEcomPush({
    event: 'view_cart',
    ecommerce: {
      currency: 'GBP',
      value: convertCentAmountToDollars(totalPrice.centAmount),
      items: lineItems.map(mapLineItemForGA4),
    },
  });
}

/**
 * Track checkout start
 * @param cart
 */
export function trackBeginCheckout(
  totalPrice: MoneyFragment,
  lineItems: LineItemDataFragment[],
) {
  dataLayerEcomPush({
    event: 'begin_checkout',
    ecommerce: {
      // UA
      checkout: {
        actionField: { step: 1 },
        products: lineItems.map(mapLineItemForUA),
      },
      // GA4
      currency: 'GBP',
      value: convertCentAmountToDollars(totalPrice.centAmount),
      items: lineItems.map(mapLineItemForGA4),
      // coupon
    },
  });
}

/**
 * Track delivery step
 * @param cart
 */
export function trackAddDeliveryInfo(
  totalPrice: MoneyFragment,
  lineItems: LineItemDataFragment[],
) {
  dataLayerEcomPush({
    event: 'add_shipping_info',
    ecommerce: {
      // UA
      checkout: {
        actionField: { step: 2 },
        products: lineItems.map(mapLineItemForUA),
      },
      // GA4
      currency: 'GBP',
      value: convertCentAmountToDollars(totalPrice.centAmount),
      items: lineItems.map(mapLineItemForGA4),
      // coupon, shipping_tier
    },
  });
}

/**
 * Track payment step
 * @param cart
 */
export function trackAddPaymentInfo(
  totalPrice: MoneyFragment,
  lineItems: LineItemDataFragment[],
) {
  dataLayerEcomPush({
    event: 'add_payment_info',
    ecommerce: {
      // UA
      checkout: {
        actionField: { step: 3 },
        products: lineItems.map(mapLineItemForUA),
      },
      // GA4
      currency: 'GBP',
      value: convertCentAmountToDollars(totalPrice.centAmount),
      items: lineItems.map(mapLineItemForGA4),
      // coupon, payment_type
    },
  });
}

/**
 * Adds a successful transaction to the Google Tag Manager dataLayer
 * @todo add category and brand
 * @param {IGroupedOrder | null} order
 * @returns
 */
export function trackSuccessfulTransaction(order: IGroupedOrder | null) {
  if (!order) {
    return;
  }

  dataLayerEcomPush({
    event: 'purchase',
    // Basic Ecommerce, needed for Universal Analytics (no idea why)
    transactionId: order.orderNumber || order.id,
    transactionTotal: convertCentAmountToDollars(order.totalPrice.centAmount),
    transactionShipping: order.shippingInfo
      ? convertCentAmountToDollars(order.shippingInfo.price.centAmount)
      : null,
    transactionProducts: order.lineItems.map(
      ({ name, variant, quantity, price }) => ({
        name,
        sku: variant?.sku,
        price: price && convertCentAmountToDollars(price.value.centAmount),
        quantity,
      }),
    ),
    ecommerce: {
      // UA
      purchase: {
        actionField: {
          id: order.orderNumber || order.id,
          revenue: convertCentAmountToDollars(order.totalPrice.centAmount),
          shipping: order.shippingInfo
            ? convertCentAmountToDollars(order.shippingInfo.price.centAmount)
            : null,
          coupon: order.discountCodes?.[0]
            ? order.discountCodes?.[0].discountCode?.code
            : null,
        },
        products: order.lineItems.map(mapLineItemForUA),
      },
      // GA4
      currency: order.totalPrice.currencyCode,
      transaction_id: order.orderNumber || order.id,
      value: convertCentAmountToDollars(order.totalPrice.centAmount),
      coupon: order.discountCodes?.[0]
        ? order.discountCodes?.[0].discountCode?.code
        : null,
      shipping: order.shippingInfo
        ? convertCentAmountToDollars(order.shippingInfo.price.centAmount)
        : null,
      netValueExcludingShipping:
        order.taxedPrice && order.shippingInfo && order.shippingInfo.taxedPrice
          ? convertCentAmountToDollars(
              order.taxedPrice.totalNet.centAmount -
                order.shippingInfo.taxedPrice.totalNet.centAmount,
            )
          : null,
      items: order.lineItems.map(mapLineItemForGA4),
    },
  });
}

/**
 * Track email sign ups
 * @param source the component from which this fired
 */
export function trackMarketingEmailSignup(source: string) {
  dataLayerPush({
    event: 'marketing_sign_up',
    event_source: source,
  });
}

/**
 * Track email popup dismissals
 */
export function trackMarketingEmailDismiss() {
  dataLayerPush({
    event: 'marketing_popup_dismiss',
  });
}

/**
 * Track how long the email popup stayed open, and whether it was a successful signup
 */
export function trackMarketingPopupTime({
  time = 0,
  success = false,
}: PopupTimeProps) {
  dataLayerPush({
    event: 'marketing_popup_time',
    time_open: time,
    signup_status: success ? 'Sign up' : 'Popup Close',
  });
}
