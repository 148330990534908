import styled from 'styled-components';

import theme from 'utils/theme';

interface Heading6Props {
  inline?: boolean;
}

const Heading6 = styled.h6<Heading6Props>`
  margin-top: 0;
  margin-bottom: ${({ inline }) => (inline ? 0 : '20px')};
  font-size: var(--step-2);
  line-height: 1.2;
  letter-spacing: -0.2px;
  font-weight: ${theme.weights.headingBold};
  color: inherit;
  font-family: ${theme.fonts.heading};

  p + &,
  h1 + &,
  h2 + & {
    margin-top: ${({ inline }) => (inline ? 0 : '1.5em')};
  }
`;

export default Heading6;
