import React, { useState, useRef, useEffect, useCallback } from 'react';

import { IconButton } from 'components/common/header/styles';
import { Search as SearchIcon, Close } from 'components/common/icons';
import Logo from 'components/common/logo';

import {
  Input,
  SearchOverlay,
  SearchForm,
  SearchIconLabel,
  SearchLogoWrapper,
  SearchHeaderInner,
  SearchHeaderWrapper,
} from './styles';

const Search = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [timeoutIndex, setTimeoutIndex] = useState<number | null>();
  const inputRef = useRef<HTMLInputElement>(null);

  const focusOnSearch = useCallback(() => {
    setTimeoutIndex(
      setTimeout(() => {
        if (!isOpen) return;

        inputRef && inputRef.current && inputRef.current.focus();

        /**
         * Refocus if the browser hasn't caught up yet
         */
        if (inputRef.current !== document.activeElement) {
          focusOnSearch();
        }
      }, 100),
    );
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      focusOnSearch();
    }
  }, [isOpen, focusOnSearch]);

  useEffect(() => {
    return () => {
      timeoutIndex && clearTimeout(timeoutIndex);
    };
  }, [timeoutIndex]);

  return (
    <>
      <SearchIconLabel
        as="label"
        htmlFor="search-input"
        data-testid="search"
        onClick={() => setIsOpen(!isOpen)}
      >
        <SearchIcon />
        Search
      </SearchIconLabel>

      <SearchOverlay active={isOpen}>
        <SearchHeaderWrapper>
          <SearchHeaderInner>
            <SearchLogoWrapper>
              <Logo />
            </SearchLogoWrapper>
            <SearchForm action="/search" method="get">
              <SearchIcon />
              <Input
                name="q"
                type="search"
                id="search-input"
                placeholder="Search"
                onFocus={() => setIsOpen(true)}
                ref={inputRef}
              />
            </SearchForm>
            <IconButton type="button" onClick={() => setIsOpen(false)}>
              <Close width={20} height={20} />
            </IconButton>
          </SearchHeaderInner>
        </SearchHeaderWrapper>
      </SearchOverlay>
    </>
  );
};

export default Search;
