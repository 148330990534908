import React, { Ref } from 'react';

import LoadingText from 'components/common/loading-text';

import { ButtonEl, ChildrenWrapper } from './styles';
import { ButtonProps } from './types';

function Button(
  {
    id,
    as,
    name,
    loading,
    onClick,
    children,
    disabled,
    selected,
    colorScheme,
    width = 'auto',
    type = 'button',
    variant = 'primary',
    loadingText = 'Loading',
    'data-testid': dataTestId,
    ...rest
  }: ButtonProps,
  ref: Ref<HTMLButtonElement | undefined>,
) {
  const defaultColorScheme = variant === 'primary' ? 'pineGreen' : 'black';

  return (
    <ButtonEl
      {...rest}
      ref={ref}
      as={as}
      id={id}
      name={name}
      width={width}
      onClick={onClick}
      variant={variant}
      $loading={loading}
      selected={selected}
      type={as ? null : type}
      data-testid={dataTestId}
      aria-selected={selected}
      disabled={loading || disabled}
      aria-disabled={loading || disabled}
      colorScheme={colorScheme || defaultColorScheme}
    >
      <LoadingText text={loadingText} />
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </ButtonEl>
  );
}

const ButtonWithRef = React.forwardRef<HTMLButtonElement, ButtonProps>(Button);

export default ButtonWithRef;
