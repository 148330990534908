import { Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';

import Button from 'components/common/button';
import { useCookieAccepted } from 'components/common/cookie-banner/utils';
import Input from 'components/common/forms/input';
import { Close, LogoIcon } from 'components/common/icons';
import ModalNotification from 'components/common/modal-notification';
import SiteLink from 'components/common/site-link';
import { Heading4 } from 'components/common/text';
import {
  trackMarketingEmailDismiss,
  trackMarketingEmailSignup,
  trackMarketingPopupTime,
} from 'utils/gtm/events';
import subscribeEmail from 'utils/newsletter/subscribe';

import {
  // VariantDetails,
  Wrapper,
  CloseButton,
  StyledContainer,
  ButtonWrapper,
  BackgroundWrapper,
  FormWrapper,
} from './styles';
import { useEligibleToShow, setPopupAccepted, setPopupSeen } from './utils';

const EmailPopup = () => {
  const [submitState, setSubmitState] = useState<null | 'success'>(null);
  const [openTime, setOpenTime] = useState<number>(0);

  const eligibleToShow = useEligibleToShow();

  const [isModalOpen, setIsModalOpen] = useState(eligibleToShow);
  const hasCookieAccepted = useCookieAccepted();
  const isSuccessfulSubmission = submitState === 'success';

  useEffect(() => {
    if (eligibleToShow) {
      window.setTimeout(() => {
        setIsModalOpen(true);
        setOpenTime(performance.now());
      }, 2000);
    }
  }, [eligibleToShow]);

  const [formShown, setFormShown] = useState(false);

  const heading = (
    <>
      <Heading4>Join our Newsletter</Heading4>
      <p>
        Sign up to get all the latest gardening tips{' '}
        <span style={{ whiteSpace: 'nowrap' }}>and tricks.</span>
      </p>
    </>
  );

  useEffect(() => {
    if (isModalOpen) {
      setPopupSeen();
    }
  }, [isModalOpen]);

  const dismissPopup = useCallback(() => {
    setIsModalOpen(false);

    trackMarketingPopupTime({
      time: Math.round(performance.now() - openTime),
      success: isSuccessfulSubmission,
    });

    if (!isSuccessfulSubmission) {
      trackMarketingEmailDismiss();
    }
  }, [openTime, isSuccessfulSubmission]);

  let body;

  if (isSuccessfulSubmission) {
    body = (
      <>
        <Heading4>Thanks</Heading4>
        <p>We’ve got it, we’ll be in touch soon.</p>
        <ButtonWrapper>
          <Button type="button" variant="white" onClick={dismissPopup}>
            Continue Shopping
          </Button>
        </ButtonWrapper>
      </>
    );
  } else if (formShown) {
    body = (
      <>
        {heading}
        <FormWrapper>
          <Formik
            initialValues={{ email: '' }}
            onSubmit={async ({ email }, actions) => {
              setSubmitState(null);
              try {
                await subscribeEmail({ email });
                trackMarketingEmailSignup('Popup');
                setPopupAccepted();
                actions.resetForm();
                setSubmitState('success');
              } catch (error) {
                // @TODO: Error handling
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Input
                  type="email"
                  name="email"
                  autoComplete="email"
                  placeholder="Email address"
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                />
                <p>
                  By subscribing you agree to our{' '}
                  <SiteLink
                    href="/about/terms-and-conditions"
                    target="_blank"
                    underline
                  >
                    Terms & Conditions
                  </SiteLink>{' '}
                  and{' '}
                  <SiteLink
                    href="/about/privacy-policy"
                    target="_blank"
                    underline
                  >
                    Privacy & Cookies Policy
                  </SiteLink>
                  .
                </p>
                <ButtonWrapper>
                  <Button loading={isSubmitting} type="submit" variant="white">
                    Sign Me Up
                  </Button>
                </ButtonWrapper>
              </Form>
            )}
          </Formik>
        </FormWrapper>
      </>
    );
  } else {
    body = (
      <>
        {heading}
        <ButtonWrapper>
          <Button
            type="button"
            variant="white"
            onClick={() => setFormShown(true)}
          >
            Sign Me Up
          </Button>
        </ButtonWrapper>
      </>
    );
  }

  return (
    <ModalNotification
      isBespokeModal
      isOpen={isModalOpen}
      closeModal={dismissPopup}
    >
      <StyledContainer>
        <Wrapper
          cookieAccepted={hasCookieAccepted}
          isOpen={isModalOpen}
          data-testid="emailPopup"
        >
          <BackgroundWrapper>
            <LogoIcon />
            <LogoIcon />
            <LogoIcon />
          </BackgroundWrapper>
          <CloseButton type="button" onClick={dismissPopup}>
            <Close height={16} width={16} />
          </CloseButton>
          {body}
        </Wrapper>
      </StyledContainer>
    </ModalNotification>
  );
};

export default EmailPopup;
