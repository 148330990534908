import styled, { css, keyframes } from 'styled-components';

import ResponsiveBox from 'components/common/responsive-box';
import SiteLink from 'components/common/site-link';
import { desktop } from 'utils/media';
import theme from 'utils/theme';
import { IDelayedRenderProps } from 'utils/types';

export const Wrapper = styled.div<IDelayedRenderProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${theme.colors.white};
  z-index: ${theme.zIndexes.header};
  display: flex;
  flex-direction: column;
  user-select: none;

  transition: ${(props) => `transform ${props.transitionDuration}ms`};
  transform: ${(props) =>
    props.isOpen ? 'translateX(0%)' : 'translateX(100%)'};

  ${desktop} {
    display: none;
  }
`;

const MobileNavSlideIn = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateY(0%);
  }
`;

export const MobileNavScreen = styled.div<IDelayedRenderProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${theme.colors.white};
  padding: 0;
  box-shadow: -20px 0 40px rgba(0, 0, 0, 0.1);
  overflow: auto;
  display: flex;
  flex-direction: column;

  animation: ${(props) =>
    css`
      ${props.transitionDuration}ms ${MobileNavSlideIn}
    `};
  transition: ${(props) => `transform ${props.transitionDuration}ms`};
  transform: ${(props) =>
    props.isOpen ? 'translateX(0%)' : 'translateX(100%)'};
`;

export const ScreenView = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ScreenFooterLinks = styled.div`
  padding: 0 20px 20px 20px;
  padding-bottom: max(20px, env(safe-area-inset-bottom));
`;

export const MobileInternalNavigation = styled.div`
  height: 50px;
  padding: 0;
  top: var(--space-3xs);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: ${theme.zIndexes.header};
  position: relative;
  background: ${theme.colors.white};
`;

interface MobileInternalButtonAnimationProps {
  active: boolean;
}

export const MobileInternalButtonAnimation = styled.div<MobileInternalButtonAnimationProps>`
  font-size: 0;
  line-height: 0;
  opacity: ${({ active }) => (active ? 1 : 0)};
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  transition: opacity ${theme.transitionSpeeds.fast}s,
    visibility ${theme.transitionSpeeds.fast}s,
    transform ${theme.transitionSpeeds.fast}s;
  transform: ${({ active }) =>
    active ? 'translateX(0px)' : 'translateX(10px) '};
  transition-delay: ${({ active }) =>
    active ? theme.transitionSpeeds.slow : 0}s;
`;

export const MobileInternalButton = styled.button`
  display: inline-block;
  border: 0;
  background: none;
  padding: 20px;
  padding-bottom: 30px;
  margin: 0;
  line-height: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;

  &:focus,
  &:active {
    color: ${theme.colors.irisPurple};
  }

  &:focus-visible {
    outline: var(--space-3xs) solid;
  }

  @media not all and (hover: none) {
    &:hover {
      color: ${theme.colors.irisPurple};
    }
  }
`;

export const MobileContent = styled.div`
  padding: 20px;
  position: relative;
  flex: 1;
  z-index: ${theme.zIndexes.header};
`;

const navRowStyles = css`
  border: 0;
  padding: 0;
  margin: 0;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  background: none;
  width: 100%;
  font-weight: ${theme.weights.bold};
  border-bottom: 1px solid ${theme.colors.iconUnderlay};
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;

  > span {
    display: flex;
    align-items: center;

    > svg {
      margin-right: var(--space-2xs);
    }
  }

  &:focus,
  &:active {
    color: ${theme.colors.irisPurple};
  }

  &:focus-visible {
    outline: none;
    outline: var(--space-3xs) solid;
  }

  @media not all and (hover: none) {
    &:hover {
      color: ${theme.colors.irisPurple};
    }
  }

  &:last-child {
    border-bottom: 0;
  }
`;

export const NavRowButton = styled.button`
  ${navRowStyles}
`;

export const NavRowLink = styled(SiteLink)`
  ${navRowStyles}
`;

export const ScreenHero = styled(ResponsiveBox)`
  background-color: ${theme.colors.offWhite};
`;

interface ScreenHeroHeadingProps {
  hasImage: boolean;
}

export const ScreenHeroHeading = styled.div<ScreenHeroHeadingProps>`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  ${(props) => (props.hasImage ? `color: ${theme.colors.white};` : '')}
`;

export const ScreenHeroUnderlay = styled.div`
  background: ${theme.colors.iconUnderlay};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const MobileNavScreenRows = styled.div`
  padding: 20px;
  flex: 1;
`;
