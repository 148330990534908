import styled, { css } from 'styled-components';

import { desktop } from 'utils/media';
import theme from 'utils/theme';
import { IDelayedRenderProps } from 'utils/types';

export const DesktopOnly = styled.div`
  display: none;

  ${desktop} {
    display: block;
  }
`;

export const Wrapper = styled.div<IDelayedRenderProps>`
  position: absolute;
  left: 0;
  right: 0;
  z-index: ${theme.zIndexes.header - 1};
  background-color: ${theme.colors.offWhite};

  ${({ isOpen, transitionDuration }) =>
    isOpen
      ? css`
          opacity: 1;
          transform: translateY(0%);
          transition: opacity ${transitionDuration}ms,
            transform ${transitionDuration}ms;
        `
      : css`
          opacity: 0;
          transform: translateY(-20px);
          transition: opacity ${transitionDuration} ms, transform 10s; // Slow down transition out to give the appearance of a fade out
        `}
`;

export const Underlay = styled.div<IDelayedRenderProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.colors.modalBackground};
  z-index: ${theme.zIndexes.mid};

  transition: ${(props) => `opacity ${props.transitionDuration}ms`};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
`;

export const TabsRow = styled.div`
  border-top: var(--space-4xs) solid ${theme.colors.offWhite};
  background-color: ${theme.colors.white};
  padding: 20px 0;

  a {
    font-weight: ${theme.weights.bold};

    + a {
      margin-left: 30px;
    }
  }
`;

export const TabsFlexRow = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContentRow = styled.div`
  padding: 20px 0;
`;

export const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 180px);
  grid-gap: 20px 40px;
  justify-content: center;
  padding: 20px 40px;
`;

export const Column = styled.div`
  ul {
    padding-left: 20px;
  }

  li {
    margin-bottom: var(--space-2xs);
  }
`;
