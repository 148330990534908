import styled from 'styled-components';

import theme from 'utils/theme';

interface Heading3Props {
  inline?: boolean;
}

const Heading3 = styled.h3<Heading3Props>`
  margin-top: 0;
  margin-bottom: ${({ inline }) => (inline ? 0 : '20px')};
  font-size: var(--step-6);
  line-height: 1.1;
  letter-spacing: -0.2px;
  color: inherit;
  font-family: ${theme.fonts.heading};

  p + &,
  h1 + &,
  h2 + & {
    margin-top: 1.5em;
  }
`;

export default Heading3;
