import styled from 'styled-components';

import { IconButton } from 'components/common/header/styles';
import { desktop, largeDesktop } from 'utils/media';
import theme from 'utils/theme';

export const SearchIconLabel = styled(IconButton)`
  font-size: 0;

  ${largeDesktop} {
    border: 1px solid ${theme.colors.lightGrey};
    background: ${theme.colors.lightestGrey};
    font-size: 1rem;
    height: ${theme.sizes.input.height}px;
    width: 135px;
    border-radius: 40px;
    color: ${theme.colors.midGrey};
    margin: 0 15px 0 0;
    position: relative;
    cursor: text;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      color: ${theme.colors.black};
      height: 20px;
    }
  }
`;

interface SearchOverlayProps {
  active: boolean;
}

export const SearchOverlay = styled.div<SearchOverlayProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${theme.zIndexes.header};
  opacity: ${({ active }) => (active ? 1 : 0)};
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  transition: opacity ${theme.transitionSpeeds.normal}s,
    visibility ${theme.transitionSpeeds.normal}s;

  :before {
    content: '';
    display: block;
    background-color: ${theme.colors.white};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    ${desktop} {
      background-color: ${theme.colors.modalBackground};
    }
  }

  ${desktop} {
    display: block;
    width: 100%;
  }
`;

export const SearchLogoWrapper = styled.div`
  display: none;
  padding-left: 20px;
`;

export const SearchHeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px 0 24px;
  width: 100%;
  max-width: 1320px;
  margin: auto;

  ${desktop} {
    padding: 0;
  }
`;

export const SearchForm = styled.form`
  flex-grow: 1;
  position: relative;
  padding: var(--space-2xs) 0;
  height: 52px;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    width: 16px;
    height: 16px;
  }

  ${desktop} {
    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

export const SearchHeaderWrapper = styled.div`
  background: ${theme.colors.white};
  position: relative;
  padding-top: 20px;
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%;

  ${desktop} {
    background: ${theme.colors.white};
    height: 136px;
    padding-top: 36px;

    ${SearchLogoWrapper} {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.colors.irisPurple};

      svg {
        height: 30px;
        width: auto;
      }
    }

    ${SearchForm} {
      max-width: 440px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 36px;
  line-height: 36px;
  border-radius: 40px;
  border: 1px solid ${theme.colors.lightGrey};
  background: ${theme.colors.lightestGrey};
  padding: 0 0 0 40px;
  appearance: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${theme.colors.midGrey};
    opacity: 1;
  }

  ${desktop} {
    height: ${theme.sizes.input.height}px;
    line-height: ${theme.sizes.input.height}px;
  }
`;
