import styled, { CSSObject, keyframes } from 'styled-components';

import { LoadingTextContainer } from 'components/common/loading-text/styles';
import { desktop } from 'utils/media';
import theme from 'utils/theme';

export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'secondaryFilled'
  | 'secondaryOption'
  | 'tertiaryFilled'
  | 'circle'
  | 'inline'
  | 'link'
  | 'white';

interface ButtonElProps {
  colorScheme: Sproutl.ColorScheme;
  loadingText: string;
  disabled?: boolean;
  selected?: boolean;
  variant: ButtonVariants;
  width?: 'auto' | 'half' | 'full';
  $loading?: boolean;
}

const primaryColorSchemes: Partial<
  Record<
    Sproutl.ColorScheme,
    { color: string; borderColor: string; backgroundColor: string }
  >
> = {
  pineGreen: {
    color: theme.colors.lightGreen,
    borderColor: theme.colors.darkGreen,
    backgroundColor: theme.colors.darkGreen,
  },
  irisPurple: {
    color: theme.colors.black,
    borderColor: theme.colors.purple,
    backgroundColor: theme.colors.purple,
  },
};

const secondaryColorSchemes: Record<
  string,
  {
    color: string;
    border?: number | string;
    borderColor?: string;
    backgroundColor: string;
  }
> = {
  blackTransparent: {
    color: theme.colors.black,
    borderColor: theme.colors.black,
    backgroundColor: 'transparent',
  },
  blackWhite: {
    color: theme.colors.black,
    borderColor: theme.colors.black,
    backgroundColor: theme.colors.white,
  },
  white: {
    color: theme.colors.black,
    borderColor: 'transparent',
    backgroundColor: theme.colors.white,
  },
  greenTextOnly: {
    color: theme.colors.darkGreen,
    border: 0,
    backgroundColor: 'transparent',
  },
};

const variantStyles = ({
  colorScheme,
  selected,
}: ButtonElProps): Record<string, CSSObject> => ({
  defaults: {
    margin: 0,
    fontSize: 'var(--step-0)',
    borderWidth: 2,
    cursor: 'pointer',
    borderRadius: 40,
    alignItems: 'center',
    borderStyle: 'solid',
    display: 'inline-flex',
    justifyContent: 'center',

    // Properties which are sometimes overriden
    padding: '12px 24px',
    fontWeight: theme.weights.bold,
    height: theme.sizes.button.height,

    transition:
      'background-color ease-out 150ms, border-color ease-out 150ms, color ease-out 150ms, transform ease-out 150ms',

    '&:focus': {
      outline: 'none',
    },

    'svg + *': {
      marginLeft: '0.5em',
    },
  },

  primary: {
    ':hover, a:hover &, &:focus':
      colorScheme === 'pineGreen'
        ? {
            borderColor: theme.colors.coleusGreen,
            backgroundColor: theme.colors.coleusGreen,
            color: theme.colors.pineGreen,
          }
        : {},
  },

  secondary: {
    color: theme.colors[colorScheme],
    backgroundColor: 'transparent',
    borderColor: theme.colors[colorScheme],

    ':hover, a:hover &, &:focus': {
      borderColor: theme.colors[colorScheme],
      backgroundColor: theme.colors[colorScheme],
      color:
        colorScheme === 'pineGreen' ||
        colorScheme === 'black' ||
        colorScheme === 'thistleBlue'
          ? theme.colors.white
          : theme.colors.black,
    },
  },

  secondaryOption: {
    ...secondaryColorSchemes.blackWhite,

    borderColor: selected ? theme.colors.purple : theme.colors.midGrey,

    ':hover, a:hover &, &:focus': {
      borderColor: theme.colors.purple,
    },
  },

  secondaryFilled: {
    ...secondaryColorSchemes.blackWhite,

    ':hover, a:hover &, &:focus': {
      borderColor: theme.colors.purple,
    },
  },

  tertiaryFilled: {
    ...secondaryColorSchemes.blackWhite,

    ':hover, a:hover &, &:focus': {
      borderColor: theme.colors.black,
      backgroundColor: theme.colors.black,
      color: theme.colors.white,
    },
  },

  white: {
    ...secondaryColorSchemes.white,

    ':hover, a:hover &, &:focus': {
      backgroundColor: theme.colors.black,
      color: theme.colors.white,
    },

    '&:focus-visible': {
      boxShadow: `0 0 0 var(--space-4xs) ${theme.colors.purple}`,
    },
  },

  circle: {
    ...secondaryColorSchemes.blackTransparent,

    padding: '12px 11px',
    width: theme.sizes.button.height,

    ':hover, a:hover &, &:focus': {
      borderColor: theme.colors.purple,
    },
  },

  inline: {
    ...secondaryColorSchemes.greenTextOnly,

    padding: 0,
    height: 'auto',
    fontWeight: theme.weights.normal,

    ':hover, a:hover &': {
      color: theme.colors.black,
    },
  },

  link: {
    ...secondaryColorSchemes.greenTextOnly,

    padding: 0,
    height: 'auto',
    fontWeight: theme.weights.normal,

    textDecoration: 'underline',
  },
});

const variantDisabledStyles = ({
  selected,
}: ButtonElProps): Record<string, any> => ({
  default: {
    color: theme.colors.midGrey,
    borderColor: theme.colors.midGrey,
    backgroundColor: theme.colors.lighterGrey,

    cursor: 'inherit',
    pointerEvents: 'none',

    '&, &:hover, &:focus': {
      borderColor: theme.colors.midGrey,
    },
  },

  secondaryOption: {
    background: theme.colors.lighterGrey,
    borderColor: selected ? theme.colors.black : theme.colors.midGrey,
    position: 'relative',
    overflow: 'hidden',

    ':before': {
      position: 'absolute',
      content: '""',
      left: 0,
      top: '50%',
      right: 0,
      borderTop: '2px solid',
      borderColor: theme.colors.midGrey,

      transform: 'rotate(135deg)',
    },

    ':hover, &:focus': {
      borderColor: selected ? theme.colors.black : theme.colors.midGrey,
    },
  },

  inline: {
    color: theme.colors.grey,
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    pointerEvents: 'none',
    cursor: 'inherit',

    '&, &:hover, &:focus': {
      borderColor: 'transparent',
    },
  },
});

export const ChildrenWrapper = styled.span`
  display: contents;
`;

export const LoadingWrapper = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: inherit;
  align-items: center;
  justify-content: center;
`;

export const ButtonEl = styled.button<ButtonElProps>((props) => {
  const { colorScheme, disabled, $loading = false, variant, width } = props;

  const variantStyle = variantStyles(props);
  const variantDisabledStyle = variantDisabledStyles(props);

  return {
    position: 'relative',
    color: theme.colors.lightGreen,
    borderColor: theme.colors.darkGreen,
    backgroundColor: theme.colors.darkGreen,

    // Get color scheme value or return defaults if undefined. This is for primary buttons only
    ...(colorScheme && colorScheme in primaryColorSchemes
      ? primaryColorSchemes[colorScheme]
      : {}),

    // Set the width which can be overridden by the variants
    width: width === 'full' ? '100%' : width === 'half' ? '100%' : 'auto',
    [desktop]: {
      width: width === 'full' ? '100%' : width === 'half' ? '50%' : 'auto',
    },

    // Get default styles
    ...variantStyle.defaults,

    // Get variant specific styles
    ...variantStyle[variant],

    '&:active': {
      transform: 'scale(0.95)',
    },

    // Override with disabled styles if needed
    ...(disabled && !$loading
      ? variantDisabledStyle[variant] || variantDisabledStyle.default
      : {}),

    ...($loading
      ? {
          pointerEvents: 'none',
          userSelect: 'none',
          [LoadingTextContainer]: { display: 'flex' },
          [ChildrenWrapper]: { display: 'none' },
        }
      : {}),
  };
});

const dotScale = keyframes`
  0% {
    transform: scale(0.5);
  }

  20% {
    transform: scale(1);
  }

70% {
  transform: scale(0.5);
}

100% {
  transform: scale(0.5);
}
`;

export const LoadingDot = styled.span`
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 100%;
  margin: 0 0.1em;
  line-height: 0;
  background-color: currentColor;
  animation: ${dotScale} 1s linear infinite;
  transform: scale(0.5);
  transform-origin: center center;

  &:nth-child(1) {
    animation-delay: 0s;
  }

  &:nth-child(2) {
    animation-delay: 0.3s;
  }

  &:nth-child(3) {
    animation-delay: 0.6s;
  }
`;
