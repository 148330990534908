import styled from 'styled-components';

import Container from 'components/common/container';
import SiteLink from 'components/common/site-link';
import { desktop, largeDesktop, tablet } from 'utils/media';
import theme from 'utils/theme';

export const MOBILE_NAV_HEIGHT = 52;
export const DESKTOP_NAV_HEIGHT = 100;

interface IActiveProps {
  isActive?: boolean;
}

export const RelativeWrapper = styled.div`
  position: relative;
`;

export const Background = styled.nav<IActiveProps>(({ isActive = false }) => ({
  position: 'relative',
  zIndex: theme.zIndexes.header,
  backgroundColor: theme.colors.irisPurple,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: MOBILE_NAV_HEIGHT,

  [desktop]: {
    backgroundColor: isActive ? theme.colors.white : theme.colors.purple,
    height: DESKTOP_NAV_HEIGHT,
    transition: isActive
      ? `background-color ${theme.transitionSpeeds.fast}s ease-out`
      : `background-color ${theme.transitionSpeeds.fastest}s ease-out`,
  },
}));

export const StyledContainer = styled(Container)({
  display: 'flex',
  padding: '0 6px 0 20px',
  position: 'relative',

  [tablet]: {
    padding: '0 26px 0 40px',
  },

  [desktop]: {
    padding: '0 40px',
    width: '100%',
    maxWidth: theme.pageWidth.extended,
  },
});

export const LogoLink = styled.a<IActiveProps>(({ isActive = false }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.colors.white,
  height: MOBILE_NAV_HEIGHT,

  [desktop]: {
    color: isActive ? theme.colors.irisPurple : theme.colors.white,
    height: DESKTOP_NAV_HEIGHT,
    transition: isActive
      ? `color ${theme.transitionSpeeds.fast}s ease-out`
      : `color ${theme.transitionSpeeds.normal}s ease-out`,
  },

  svg: {
    height: 20,
    width: 'auto',
    [desktop]: {
      height: 30,
    },
  },
}));

export const LogoWrapper = styled.div`
  flex-shrink: 0;

  ${desktop} {
    width: 155px;
    min-width: 155px;
  }

  ${largeDesktop} {
    width: 270px;
    min-width: 270px;
  }
`;

export const HeaderNavLinks = styled.ul`
  align-items: center;
  display: none;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;

  li + li {
    margin-left: 20px;

    ${largeDesktop} {
      margin-left: 30;
    }
  }

  a {
    font-weight: ${theme.weights.bold};
    color: ${theme.colors.black};
    padding: 12px 0;

    &:hover,
    &:focus {
      color: ${theme.colors.black};
    }
  }

  ${desktop} {
    display: flex;
  }
`;

export const HamburgerTempWrapper = styled.button({
  width: MOBILE_NAV_HEIGHT,
  height: MOBILE_NAV_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'none',
  border: 0,

  [desktop]: {
    height: 'initial',
    cursor: 'pointer',
  },

  [desktop]: {
    display: 'none',
  },
});

export const IconLink = styled(SiteLink)({
  color: theme.colors.black,
});

export const IconPlainLink = styled.a`
  color: ${theme.colors.black};
`;

export const IconButton = styled.button({
  width: MOBILE_NAV_HEIGHT,
  height: MOBILE_NAV_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'none',
  border: 0,
  cursor: 'pointer',

  svg: {
    pointerEvents: 'none',
  },

  [largeDesktop]: {
    width: 60,
    height: DESKTOP_NAV_HEIGHT,
    svg: {
      height: 30,
      width: 'auto',
    },
  },
});

export const IconsWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',

  [desktop]: {
    marginRight: '-15px',
  },
});
